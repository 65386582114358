import React from "react";
import { faBoxFull } from "@fortawesome/pro-regular-svg-icons/faBoxFull";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const defaultTranslations = {
  pluginName: "Benchmarx Products",
  pluginDescription: "Display products returned from Algolia.",
};

export const defaultSettings = {
  Controls: () => <>Controls for this plugin were not provided</>,
  Renderer: () => <>Renderer for this plugin was not provided</>,
  translations: defaultTranslations,
  IconComponent: <FontAwesomeIcon icon={faBoxFull} size="3x" />,
};
