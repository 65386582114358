import { Container, Carousel } from "@peracto/peracto-blocks";

import ImagesCarousel from "./ImagesCarousel";
import BenchProducts from "./BenchProducts";

export default {
  plugins: {
    content: [Carousel, ImagesCarousel, BenchProducts],
    layout: [Container],
  },
};
